import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Simplify } from "conditional-render-simplify";
import styles from "./Icon.module.scss";
import {
  HiOutlineCursorClick,
  HiOutlineChevronDoubleRight,
  HiOutlineChevronDoubleLeft,
  HiPhone,
  HiOutlineDotsVertical,
  HiOutlineHome,
  HiOutlineArrowLeft,
  HiArrowNarrowRight,
  HiOutlineDuplicate,
} from "react-icons/hi";
import { BiSearch, BiErrorCircle, BiUser, BiCodeCurly, BiCheck } from "react-icons/bi";
import { AiFillInfoCircle, AiOutlineInfoCircle, AiOutlineMenu, AiOutlineSchedule } from "react-icons/ai";
import { FiBarChart2, FiCheckCircle, FiClock, FiCopy, FiPlus, FiSend } from "react-icons/fi";
import {
  MdOutlineTextsms,
  MdOutlineDrafts,
  MdOutlineAssignment,
  MdDragIndicator,
  MdOutlineSell,
  MdHourglassEmpty,
  MdFlashOn,
  MdSort,
  MdChevronRight,
  MdChevronLeft,
  MdShare,
  MdHowToReg,
  MdPerson,
  MdReport,
  MdOutlineLocalAtm,
  MdShoppingCart,
  MdCallMissedOutgoing,
  MdVisibility,
  MdArrowUpward,
  MdChat,
  MdCheck,
  MdArrowDropDown,
  MdArrowDropUp,
  MdCancel,
  MdMenu,
  MdOutlinePlace,
  MdPlace,
  MdAdd,
  MdRemove,
  MdAttachFile,
  MdMessage,
  MdOutlineReplay,
  MdSave,
  MdCalendarToday,
  MdStart,
  MdCloudUpload,
  MdLocalOffer,
  MdBrightness1,
  MdArrowForward,
  MdWarning,
  MdInsights,
  MdCamera,
  MdCircle,
  MdAddCircle,
  MdDoneAll,
  MdFingerprint,
  MdLightbulb,
  MdSecurity,
  MdCelebration,
  MdChangeCircle,
  MdPhoto,
  MdFileUpload,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdOutlineRadioButtonChecked,
  MdOutlineRadioButtonUnchecked,
  MdCreate,
  MdEvent,
  MdCardGiftcard,
  MdAccountBox,
  MdSkipNext,
  MdSkipPrevious,
  MdConfirmationNumber,
  MdCampaign,
  MdOutlineMail,
  MdEmail,
  MdDelete,
  MdOutlineArchive,
  MdOutlinePayments,
  MdOutlineAssignmentLate,
  MdOutlineLeaderboard,
  MdOutlineHandshake,
  MdOutlineChangeCircle,
  MdOutlineChair,
  MdOutlineBusinessCenter,
  MdOutlineAdminPanelSettings,
  MdOutlineKeyboardCommandKey,
  MdEdit,
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineRefresh,
  MdOutlineAddBox,
  MdOutlineApps,
  MdOutlineNotificationsNone,
  MdOutlineGesture,
  MdOutlineTimelapse,
  MdLabel,
  MdLabelOutline,
  MdError,
  MdReceipt,
  MdEmojiObjects,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdAccessTime,
  MdWeb,
  MdClear,
  MdStars,
  MdOutlineShoppingCart,
  MdOutlineFestival,
  MdOutlineSupportAgent,
  MdOutlineQuestionMark,
  MdVideoSettings,
  MdAppSettingsAlt,
  MdOutlineShareLocation,
  MdOutlineCampaign,
  MdOutlineQrCodeScanner,
  MdOutlineChecklistRtl,
  MdOutlineGrid3X3,
  MdOutlineBusiness,
  MdOutlineRocketLaunch,
  MdOutlineLockReset,
  MdOutlineUnpublished,
  MdOutlineReorder,
  MdOutlineOndemandVideo,
  MdRecommend,
  MdOutlineLock,
  MdBlock,
  MdWebhook,
  MdKeyboardDoubleArrowRight,
  MdKeyboardDoubleArrowLeft,
  MdOutlinePalette,
  MdVerified,
} from "react-icons/md";
import {
  RiMailSendLine,
  RiMailCheckLine,
  RiMailOpenLine,
  RiEqualizerFill,
  RiHome5Line,
  RiPrinterLine,
  RiLayoutLine,
  RiTimerLine,
  RiPercentLine,
  RiHeart2Line,
  RiShoppingBagLine,
  RiShoppingCartLine,
  RiFileList2Line,
  RiCalendarLine,
  RiStarSFill,
  RiTicket2Fill,
  RiSettings5Line,
  RiQuestionLine,
  RiArrowLeftRightLine,
  RiRefund2Line,
  RiShoppingBasketLine,
  RiBankCardLine,
  RiBillLine,
  RiImage2Line,
  RiExchangeDollarLine,
  RiTicket2Line,
  RiVipCrown2Line,
  RiPlayFill,
  RiHeartFill,
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiQuestionAnswerLine,
  RiDeleteBin6Line,
  RiRepeatOneFill,
  RiStarLine,
  RiSettings3Line,
  RiPassValidLine,
  RiProhibitedLine,
  RiUserAddLine,
} from "react-icons/ri";
import { IoAnalytics, IoCheckmarkCircle, IoCheckmarkCircleOutline, IoClose, IoEarthOutline, IoFilter, IoReceiptOutline, IoSendSharp, IoStatsChart, IoWalletOutline } from "react-icons/io5";
import {
  TbAffiliate,
  TbBoxMultiple,
  TbBrandGoogleAnalytics,
  TbBuildingArch,
  TbBulbFilled,
  TbCalendarDollar,
  TbClipboardList,
  TbClockDollar,
  TbDiscount2,
  TbFilterCheck,
  TbFilterOff,
  TbFilterSearch,
  TbFilterX,
  TbMail,
  TbMap2,
  TbMessage2Question,
  TbNotes,
  TbReceipt,
  TbReport,
  TbReportMoney,
  TbSettingsX,
  TbShoppingCartCheck,
  TbShoppingCartX,
  TbTruckDelivery,
  TbUserSearch,
  TbUserShare,
  TbUserShield,
  TbUserX,
  TbVideoOff,
  TbWorld,
  TbWorldWww,
} from "react-icons/tb";
import { BsExclamationCircleFill, BsFillEyeFill, BsFillEyeSlashFill, BsFillSunFill, BsLink45Deg, BsTrophyFill } from "react-icons/bs";
import { useTenantContext } from "context/TenantContext";
import { useEventContext } from "context/EventContext";
import { GiFairyWand } from "react-icons/gi";
import { GrRobot, GrFormPrevious, GrNext, GrMoney, GrLineChart } from "react-icons/gr";
import { RxDashboard } from "react-icons/rx";
import {
  FaPlayCircle,
  FaRegCheckCircle,
  FaRegCircle,
  FaRegIdCard,
  FaStripe,
  FaRegFileImage,
  FaFacebookF,
  FaDiscord,
  FaRegEnvelope,
  FaHeart,
  FaRegStar,
  FaGift,
  FaDoorOpen,
  FaTwitter,
} from "react-icons/fa";
import { IoMdInfinite } from "react-icons/io";
import { CgTemplate } from "react-icons/cg";
import { FaCircleDollarToSlot, FaPeopleGroup, FaUsersRays, FaUsersViewfinder } from "react-icons/fa6";
import { LuArchiveRestore, LuLogOut, LuSearch, LuTicket, LuZoomIn, LuZoomOut } from "react-icons/lu";
import { GoTrophy, GoVerified } from "react-icons/go";
import { LiaUsersSolid } from "react-icons/lia";
import { PiArrowCircleUp, PiConfettiBold } from "react-icons/pi";

function Icons(props) {
  const { type, size, className, onClick, isIconButton, containerClassName, style, anchorEl, areaExpanded, isActionButton } = props;
  const commonClass = classNames([className, styles.icon, styles[`size-${size}`]]);
  const { eventData } = useEventContext();
  const { currency } = useTenantContext();

  const iconProps = {
    className: commonClass,
    onClick: (e) => (!isIconButton || !isActionButton ? onClick(e) : {}),
    style: style,
    "aria-owns": anchorEl,
    "aria-expanded": areaExpanded,
  };

  return (
    <div
      className={classNames({ [styles.iconButton]: isIconButton, [styles.actionButton]: isActionButton, [containerClassName]: !!containerClassName, "cursor-pointer": isIconButton || isActionButton })}
      onClick={() => (isIconButton || isActionButton ? onClick() : {})}
    >
      <Simplify
        conditions={type}
        mail={<MdOutlineMail {...iconProps} />}
        mailRounded={<MdEmail {...iconProps} />}
        sms={<MdOutlineTextsms {...iconProps} />}
        message={<MdMessage {...iconProps} />}
        rightCheck={<FiCheckCircle {...iconProps} />}
        checkCircle={<IoCheckmarkCircle {...iconProps} />}
        checkCircleOutline={<IoCheckmarkCircleOutline {...iconProps} />}
        glassEmpty={<MdHourglassEmpty {...iconProps} />}
        draft={<MdOutlineDrafts {...iconProps} />}
        errorCircle={<BiErrorCircle {...iconProps} />}
        errorCircleSolid={<MdError {...iconProps} />}
        mailCheck={<RiMailCheckLine {...iconProps} />}
        sent={<RiMailSendLine {...iconProps} />}
        opened={<RiMailOpenLine {...iconProps} />}
        filterMenu={<RiEqualizerFill {...iconProps} />}
        filter={<TbFilterOff {...iconProps} />}
        filterData={<IoFilter {...iconProps} />}
        user={<BiUser {...iconProps} />}
        cursorClick={<HiOutlineCursorClick {...iconProps} />}
        search={<BiSearch {...iconProps} />}
        home={<RiHome5Line {...iconProps} />}
        arrowRight={<HiOutlineChevronDoubleRight {...iconProps} />}
        arrowLeft={<HiOutlineChevronDoubleLeft {...iconProps} />}
        delete={<RiDeleteBin6Line {...iconProps} />}
        deleteSolid={<MdDelete {...iconProps} />}
        close={<IoClose {...iconProps} />}
        printer={<RiPrinterLine {...iconProps} />}
        layout={<RiLayoutLine {...iconProps} />}
        timer={<RiTimerLine {...iconProps} />}
        assignment={<MdOutlineAssignment {...iconProps} />}
        percentage={<RiPercentLine {...iconProps} />}
        location={<MdPlace {...iconProps} />}
        locationOutline={<MdOutlinePlace {...iconProps} />}
        heart={<RiHeart2Line {...iconProps} />}
        bag={<RiShoppingBagLine {...iconProps} />}
        cart={<RiShoppingCartLine {...iconProps} />}
        note={<RiFileList2Line {...iconProps} />}
        calender={<RiCalendarLine {...iconProps} />}
        star={<RiStarSFill {...iconProps} />}
        starOutline={<RiStarLine {...iconProps} />}
        drag={<MdDragIndicator {...iconProps} />}
        trophy={<BsTrophyFill {...iconProps} />}
        receipt={<IoReceiptOutline {...iconProps} />}
        mdReceipt={<MdReceipt {...iconProps} />}
        ticketFill={<RiTicket2Fill {...iconProps} />}
        ticketLine={<RiTicket2Line {...iconProps} />}
        sell={<MdOutlineSell {...iconProps} />}
        settings={<RiSettings5Line {...iconProps} />}
        question={<RiQuestionLine {...iconProps} />}
        doubleArrow={<RiArrowLeftRightLine {...iconProps} />}
        refund={<RiRefund2Line {...iconProps} />}
        shoppingBasket={<RiShoppingBasketLine {...iconProps} />}
        bankCard={<RiBankCardLine {...iconProps} />}
        sun={<BsFillSunFill {...iconProps} />}
        bill={<RiBillLine {...iconProps} />}
        link={<BsLink45Deg {...iconProps} />}
        exchange={<RiExchangeDollarLine {...iconProps} />}
        image={<RiImage2Line {...iconProps} />}
        crown={<RiVipCrown2Line {...iconProps} />}
        copy={<FiCopy {...iconProps} />}
        check={<MdCheck {...iconProps} />}
        phone={<HiPhone {...iconProps} />}
        info={<AiOutlineInfoCircle {...iconProps} />}
        infoFill={<AiFillInfoCircle {...iconProps} />}
        moreVert={<HiOutlineDotsVertical {...iconProps} />}
        campaign={<MdCampaign {...iconProps} />}
        flash={<MdFlashOn {...iconProps} />}
        clock={<FiClock {...iconProps} />}
        exclamation={<BsExclamationCircleFill {...iconProps} />}
        sort={<MdSort {...iconProps} />}
        rightArrow={<MdChevronRight {...iconProps} />}
        leftArrow={<MdChevronLeft {...iconProps} />}
        skipBack={<MdSkipPrevious {...iconProps} />}
        skipPlay={<RiPlayFill {...iconProps} />}
        skipNext={<MdSkipNext {...iconProps} />}
        favorite={<RiHeartFill {...iconProps} />}
        share={<MdShare {...iconProps} />}
        downArrow={<RiArrowDownSLine {...iconProps} />}
        upArrow={<RiArrowUpSLine {...iconProps} />}
        confirmationNumber={<MdConfirmationNumber {...iconProps} />}
        howToReg={<MdHowToReg {...iconProps} />}
        person={<MdPerson {...iconProps} />}
        report={<MdReport {...iconProps} />}
        localAtm={<MdOutlineLocalAtm {...iconProps} />}
        shoppingCart={<MdShoppingCart {...iconProps} />}
        missedCall={<MdCallMissedOutgoing {...iconProps} />}
        visibility={<MdVisibility {...iconProps} />}
        arrowUpward={<MdArrowUpward {...iconProps} />}
        chat={<MdChat {...iconProps} />}
        dropDownArrow={<MdArrowDropDown {...iconProps} />}
        dropDownUp={<MdArrowDropUp {...iconProps} />}
        cancel={<MdCancel {...iconProps} />}
        menu={<MdMenu {...iconProps} />}
        add={<MdAdd {...iconProps} />}
        remove={<MdRemove {...iconProps} />}
        attachFile={<MdAttachFile {...iconProps} />}
        replay={<MdOutlineReplay {...iconProps} />}
        save={<MdSave {...iconProps} />}
        calendarToday={<MdCalendarToday {...iconProps} />}
        start={<MdStart {...iconProps} />}
        cloudUpload={<MdCloudUpload {...iconProps} />}
        localOffer={<MdLocalOffer {...iconProps} />}
        brightness1={<MdBrightness1 {...iconProps} />}
        arrowForward={<MdArrowForward {...iconProps} />}
        warning={<MdWarning {...iconProps} />}
        insights={<MdInsights {...iconProps} />}
        camera={<MdCamera {...iconProps} />}
        circle={<MdCircle {...iconProps} />}
        addCircle={<MdAddCircle {...iconProps} />}
        doneAll={<MdDoneAll {...iconProps} />}
        fingerPrint={<MdFingerprint {...iconProps} />}
        lightBulb={<MdLightbulb {...iconProps} />}
        security={<MdSecurity {...iconProps} />}
        celebration={<MdCelebration {...iconProps} />}
        changeCircle={<MdChangeCircle {...iconProps} />}
        photo={<MdPhoto {...iconProps} />}
        fileUpload={<MdFileUpload {...iconProps} />}
        checkBox={<MdCheckBox {...iconProps} />}
        unCheckBox={<MdCheckBoxOutlineBlank {...iconProps} />}
        checkedRadio={<MdOutlineRadioButtonChecked {...iconProps} />}
        unCheckedRadio={<MdOutlineRadioButtonUnchecked {...iconProps} />}
        create={<MdCreate {...iconProps} />}
        event={<MdEvent {...iconProps} />}
        cardGift={<MdCardGiftcard {...iconProps} />}
        accountBox={<MdAccountBox {...iconProps} />}
        tenantCurrency={<div {...iconProps}>{currency?.symbol || "$"} </div>}
        eventCurrency={<div {...iconProps}>{eventData?.currency?.symbol || "$"} </div>}
        noUsers={<TbUserX {...iconProps} />}
        noFilterUsers={<TbUserSearch {...iconProps} />}
        orders={<TbShoppingCartCheck {...iconProps} />}
        noOrders={<TbShoppingCartX {...iconProps} />}
        noArchive={<MdOutlineArchive {...iconProps} />}
        noInactive={<TbFilterX {...iconProps} />}
        noSearchFilter={<TbFilterSearch {...iconProps} />}
        noActive={<TbFilterCheck {...iconProps} />}
        affiliate={<TbAffiliate {...iconProps} />}
        discount={<TbDiscount2 {...iconProps} />}
        noFee={<MdOutlinePayments {...iconProps} />}
        settingsDisabled={<TbSettingsX {...iconProps} />}
        noVideo={<TbVideoOff {...iconProps} />}
        stats={<IoStatsChart {...iconProps} />}
        activityLog={<TbNotes {...iconProps} />}
        noAssignment={<MdOutlineAssignmentLate {...iconProps} />}
        noTrackingCode={<TbUserShare {...iconProps} />}
        noLeaderboard={<MdOutlineLeaderboard {...iconProps} />}
        mapLocation={<TbMap2 {...iconProps} />}
        faqs={<TbMessage2Question {...iconProps} />}
        delivery={<TbTruckDelivery {...iconProps} />}
        reserveHold={<MdOutlineHandshake {...iconProps} />}
        combo={<TbBoxMultiple {...iconProps} />}
        agenda={<TbClipboardList {...iconProps} />}
        change={<MdOutlineChangeCircle {...iconProps} />}
        schedule={<AiOutlineSchedule {...iconProps} />}
        seatingHall={<TbBuildingArch {...iconProps} />}
        seatingRow={<AiOutlineMenu {...iconProps} />}
        seatingSeat={<MdOutlineChair {...iconProps} />}
        businessBag={<MdOutlineBusinessCenter {...iconProps} />}
        www={<TbWorldWww {...iconProps} />}
        world={<TbWorld {...iconProps} />}
        admin={<MdOutlineAdminPanelSettings {...iconProps} />}
        wallet={<IoWalletOutline {...iconProps} />}
        bocaTemplate={<BiCodeCurly {...iconProps} />}
        command={<MdOutlineKeyboardCommandKey {...iconProps} />}
        questionAnswer={<RiQuestionAnswerLine {...iconProps} />}
        edit={<MdEdit {...iconProps} />}
        checkMark={<MdOutlineCheckBox {...iconProps} />}
        emptyCheckMark={<MdOutlineCheckBoxOutlineBlank {...iconProps} />}
        refresh={<MdOutlineRefresh {...iconProps} />}
        useThis={<MdOutlineAddBox {...iconProps} />}
        app={<MdOutlineApps {...iconProps} />}
        notification={<MdOutlineNotificationsNone {...iconProps} />}
        eye={<BsFillEyeFill {...iconProps} />}
        eyeOff={<BsFillEyeSlashFill {...iconProps} />}
        gesture={<MdOutlineGesture {...iconProps} />}
        timeLapse={<MdOutlineTimelapse {...iconProps} />}
        plus={<FiPlus {...iconProps} />}
        repeatOne={<RiRepeatOneFill {...iconProps} />}
        label={<MdLabel {...iconProps} />}
        labelOutline={<MdLabelOutline {...iconProps} />}
        mailSend={<IoSendSharp {...iconProps} />}
        biCheck={<BiCheck {...iconProps} />}
        emoji={<MdEmojiObjects {...iconProps} />}
        back={<MdKeyboardArrowLeft {...iconProps} />}
        ahead={<MdKeyboardArrowRight {...iconProps} />}
        accessTime={<MdAccessTime {...iconProps} />}
        weblayout={<MdWeb {...iconProps} />}
        clear={<MdClear {...iconProps} />}
        wand={<GiFairyWand {...iconProps} />}
        robot={<GrRobot {...iconProps} />}
        donateStar={<MdStars {...iconProps} />}
        previous={<GrFormPrevious {...iconProps} />}
        next={<GrNext {...iconProps} />}
        logout={<LuLogOut {...iconProps} />}
        side-dashboard={<RxDashboard {...iconProps} />}
        side-orders={<MdOutlineShoppingCart {...iconProps} />}
        side-events={<MdOutlineFestival {...iconProps} />}
        side-tribe={<MdOutlineSupportAgent {...iconProps} />}
        side-registration={<FaRegIdCard {...iconProps} />}
        side-affiliate={<TbAffiliate {...iconProps} />}
        side-discounts={<TbDiscount2 {...iconProps} />}
        side-questions={<MdOutlineQuestionMark {...iconProps} />}
        side-videos={<MdVideoSettings {...iconProps} />}
        side-boomerang={<IoMdInfinite {...iconProps} />}
        side-appSetting={<MdAppSettingsAlt {...iconProps} />}
        side-venue={<MdOutlineShareLocation {...iconProps} />}
        side-fees={<TbReportMoney {...iconProps} />}
        side-site={<TbWorldWww {...iconProps} />}
        side-theme={<MdOutlinePalette {...iconProps} />}
        side-mail={<TbMail {...iconProps} />}
        side-receipt={<TbReceipt {...iconProps} />}
        side-analytics={<IoAnalytics {...iconProps} />}
        side-stripe={<FaStripe {...iconProps} />}
        side-template={<CgTemplate {...iconProps} />}
        side-admin={<TbUserShield {...iconProps} />}
        side-campaign={<MdOutlineCampaign {...iconProps} />}
        side-guests={<FaPeopleGroup {...iconProps} />}
        side-boxOffice={<LuTicket {...iconProps} />}
        side-scan={<MdOutlineQrCodeScanner {...iconProps} />}
        side-guestList={<MdOutlineChecklistRtl {...iconProps} />}
        side-search={<LuSearch {...iconProps} />}
        side-home={<HiOutlineHome {...iconProps} />}
        side-removeFilter={<TbFilterOff {...iconProps} />}
        side-bar={<FiBarChart2 {...iconProps} />}
        side-grid={<MdOutlineGrid3X3 {...iconProps} />}
        side-config={<RiSettings3Line {...iconProps} />}
        side-report={<TbReport {...iconProps} />}
        side-organization={<MdOutlineBusiness {...iconProps} />}
        side-associates={<FaUsersRays {...iconProps} />}
        side-sweepstakes={<GoTrophy {...iconProps} />}
        side-emailTemplates={<TbMail {...iconProps} />}
        side-webhooks={<MdWebhook {...iconProps} />}
        side-revenue={<FaCircleDollarToSlot {...iconProps} />}
        side-billingProfile={<IoReceiptOutline {...iconProps} />}
        coins={<GrMoney {...iconProps} />}
        analytics={<TbBrandGoogleAnalytics {...iconProps} />}
        verifyCircle={<GoVerified {...iconProps} />}
        play={<FaPlayCircle {...iconProps} />}
        uncheckedCircle={<FaRegCircle {...iconProps} />}
        checkedCircle={<FaRegCheckCircle {...iconProps} />}
        allUsers={<LiaUsersSolid {...iconProps} />}
        specificUsers={<FaUsersViewfinder {...iconProps} />}
        confetti={<PiConfettiBold {...iconProps} />}
        uploadFile={<FaRegFileImage {...iconProps} />}
        lineChart={<GrLineChart {...iconProps} />}
        rocket={<MdOutlineRocketLaunch {...iconProps} />}
        passportVerification={<RiPassValidLine {...iconProps} />}
        deactivate={<RiProhibitedLine {...iconProps} />}
        restore={<LuArchiveRestore {...iconProps} />}
        send={<FiSend {...iconProps} />}
        resetPassword={<MdOutlineLockReset {...iconProps} />}
        unPublished={<MdOutlineUnpublished {...iconProps} />}
        bulb={<TbBulbFilled {...iconProps} />}
        reorder={<MdOutlineReorder {...iconProps} />}
        virtualEvent={<MdOutlineOndemandVideo {...iconProps} />}
        recommend={<MdRecommend {...iconProps} />}
        facebook={<FaFacebookF {...iconProps} />}
        twitter={<FaTwitter {...iconProps} />}
        discord={<FaDiscord {...iconProps} />}
        envelop={<FaRegEnvelope {...iconProps} />}
        startOutline={<FaRegStar {...iconProps} />}
        gift={<FaGift {...iconProps} />}
        heartFa={<FaHeart {...iconProps} />}
        door={<FaDoorOpen {...iconProps} />}
        entryLock={<MdOutlineLock {...iconProps} />}
        block={<MdBlock {...iconProps} />}
        doubleArrowRight={<MdKeyboardDoubleArrowRight {...iconProps} />}
        doubleArrowLeft={<MdKeyboardDoubleArrowLeft {...iconProps} />}
        earth={<IoEarthOutline {...iconProps} />}
        arrowLeftSingle={<HiOutlineArrowLeft {...iconProps} />}
        zoomIn={<LuZoomIn {...iconProps} />}
        zoomOut={<LuZoomOut {...iconProps} />}
        arrowUpCircle={<PiArrowCircleUp {...iconProps} />}
        rightArrowLong={<HiArrowNarrowRight {...iconProps} />}
        userAdd={<RiUserAddLine {...iconProps} />}
        duplicate={<HiOutlineDuplicate {...iconProps} />}
        paymentPlan={<TbCalendarDollar {...iconProps} />}
        upcoming={<TbClockDollar {...iconProps} />}
        verified={<MdVerified {...iconProps} />}
      />
    </div>
  );
}

export default Icons;

Icons.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["xxs", "mxs", "xs", "sm", "md", "lg", "xl", "2xl", "3xl", "4xl", "5xl", "6xl", "7xl", "8xl", "9xl", "10xl", "12xl"]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  isIconButton: PropTypes.bool,
  containerClassName: PropTypes.string,
  style: PropTypes.object,
  anchorEl: PropTypes.any,
  areaExpanded: PropTypes.any,
  isActionButton: PropTypes.bool,
};

Icons.defaultProps = {
  size: "md",
  className: "",
  onClick: () => {},
  isIconButton: false,
  containerClassName: "",
  style: {},
  anchorEl: null,
  areaExpanded: null,
  isActionButton: false,
};
